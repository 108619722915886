/* Colors */
$primary-blue: #283593;
$primary: #242939;
$background: #f4f8fa;
$white: #fff;
$black: #000;
$warn: #f1416c;
$warn-light: #fff5f8;
$faded-gray: #b0adb9;
$yellow: #ffc700;
$yellow-light: #fff8dd;
$green: #50cd89;
$green-light: #e8fff3;
$blue: #3e97ff;
$blue-light: #eef6ff;

/* Font Family */
$Roboto-Light: 'Roboto Light';
$Roboto-Light-Italic: 'Roboto Light Italic';
$Roboto: 'Roboto';
$Roboto-Medium: 'Roboto Medium';
$Roboto-Bold: 'Roboto Bold';

@use './themes/material';
@use './abstracts/variables';
@use './base/reset';
@use './base/typography';
@use './base/normalize';

body {
  background-color: variables.$background;
}

.medium {
  font-family: variables.$Roboto-Medium;
}
